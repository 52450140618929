/* Base Layout */
.referral-page {
  min-height: 100vh;
  background-color: #292929;
  /* padding: 40px 20px; */
}

.referral-container {
  background: #ffffff;
  border-radius: 16px;
  padding: 0px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05), 0 10px 15px rgba(0, 0, 0, 0.1);
  /* max-width: 800px;
  margin: 0 auto; */
}

/* Loading and Alerts */
.loading {
  text-align: center;
  padding: 40px;
  font-size: 18px;
  color: #666;
}

.error-alert {
  background-color: #fee2e2;
  color: #dc2626;
  padding: 12px 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.copied-alert {
  background-color: #34d399;
  color: white;
  padding: 12px 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  animation: fadeOut 3s forwards;
  display: flex;
  align-items: center;
  gap: 8px;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Header Styles */
.referral-header {
  margin-bottom: 4px;
  background: #3e9e58;
    color: #fff500;
    padding: 24px 8px;
    border-radius: 0 0 40px 40px;
}

.header-content {
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px; */
}

.header-content h1 {
  font-size: 32px;
  color: white;
  margin: 0;
  font-weight: 700;
  text-align: center;
}
.header-content h2 {
  font-size: 32px;
  color: #fffb15;
  margin: 8px 0 8px 24px;
  font-weight: 700;
  text-align: center;
  animation: growText 2s ease-out infinite; /* Apply the animation */
}

@keyframes growText {
  0% {
    font-size: 32px; /* Initial font size */
  }
  100% {
    font-size: 48px; /* Final font size */
  }
}

@media (max-width: 480px) {
  .header-content h2 {
    font-size: 24px; /* Smaller font size for small screens */
    margin: 8px 0 4px 0; /* Adjust margin to fit smaller screens */
    animation: growTextMobile 2s ease-out infinite; /* Animation for mobile */
    min-height: 30px;
  }

  /* Mobile version of the growText animation */
  @keyframes growTextMobile {
    0% {
      font-size: 18px; /* Initial font size for mobile */
    }
    100% {
      font-size: 24px; /* Final font size for mobile */
    }
  }
}


.header-icon {
  font-size: 32px;
}

.header-description {
  font-size: 18px;
  color: white;
  line-height: 1.6;
  margin: 0;
  text-align: center;
}

/* Share Section */
.share-section {
  /* background: #ffffff; */
  /* border-radius: 12px; */
  /* padding: 4px; */
  margin-top: 24px;
}

.share-section h2 {
  font-size: 24px;
  color: white;
  margin-bottom: 24px;
  text-align: center;
}

/* Share Grid and Buttons */
.share-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin-bottom: 10px;
}

.share-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  /* padding: 16px; */
  width: 100%;
  height: 80px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
  color: white;
}
.share-button img {
  height: 40px;
  width: 40px;
}
.share-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.share-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.share-button .icon {
  font-size: 24px;
  margin-bottom: 4px;
}

.share-button span:not(.icon) {
  white-space: nowrap;
}

/* Share Button Colors */
.whatsapp {
  background-color: #25d366;
}
.facebook {
  background-color: #1877f2;
}
.email {
  background-color: #ea4335;
}
.telegram {
  background-color: #379be5;
}
.copy {
  background-color: #ff6c40;
}
.linkedin {
  background-color: #297bb9;
}
.twitter {
  background-color: #000000;
}
.sms {
  background-color: #0e34c0;
}

/* Benefits Section */
.benefits-section {
  margin-top: 18px;
  padding: 24px;
  background: #f8fafc;
  border-radius: 12px 12px 0 0;
  border: 1px solid #e2e8f0;
}

.benefits-section h3 {
  font-size: 20px;
  color: #2d3748;
  margin: 0 0 20px 0;
}

.benefits-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.benefits-list li {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 12px 0;
  color: #4a5568;
  font-size: 15px;
  border-bottom: 1px solid #e2e8f0;
  line-height: 1.4;
}

.benefits-list li strong {
  white-space: nowrap;
  min-width: 65px;
}

.benefits-list li span {
  margin-left: auto;
  white-space: nowrap;
}

.benefits-list li:last-child {
  border-bottom: none;
}

/* Reward Note Section */
.reward-note {
  margin-top: 0px;
  padding: 16px;
  background-color: #fff8dc;
  /* border-radius: 8px; */
  border: 1px dashed #ffd700;
}

.reward-note p {
  color: #333;
  font-weight: 500;
  margin: 0 0 8px 0;
}

.reward-note ul {
  margin: 0;
  padding-left: 20px;
}

.reward-note ul li {
  color: #666;
  font-size: 14px;
  padding: 4px 0;
  border: none;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .referral-page {
    /* padding: 14px 10px; */
  }

  .referral-container {
    padding: 0px;
  }

  .header-content h1 {
    font-size: 28px;
  }

  .header-description {
    font-size: 16px;
  }

  .share-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
  }

  .share-button {
    /* height: 70px;
    padding: 12px; */
  }

  .share-button .icon {
    font-size: 20px;
  }

  .benefits-section {
    padding: 20px;
  }

  .benefits-list li {
    gap: 8px;
    align-items: flex-start;
    line-height: 1.4;
    font-size: 14px;
    padding: 10px 0;
  }

  .benefits-list li strong {
    min-width: 55px;
  }

  .benefits-list li span {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .referral-container {
    padding: 0px;
  }

  .share-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
  }

  .share-button {
    /* height: 60px;
    padding: 10px; */
    font-size: 12px;
    width: 80px;
    height: 76px;
  }

  .share-button .icon {
    font-size: 18px;
  }

  .header-content h1 {
    font-size: 24px;
  }

  .header-description {
    font-size: 14px;
  }

  .share-section h2 {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .benefits-section h3 {
    font-size: 18px;
  }

  .benefits-list li {
    gap: 6px;
    padding: 10px 0;
    font-size: 13px;
  }

  .benefits-list li strong {
    min-width: 50px;
    font-size: 13px;
  }

  .benefits-list li span {
    font-size: 12px;
  }

  .reward-note {
    padding: 12px;
  }

  .reward-note p {
    font-size: 13px;
  }

  .reward-note ul li {
    font-size: 12px;
  }
}
