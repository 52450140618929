/* Add these styles to your existing CSS file */

.sicca-card {
  margin-top: 24px;
}

.conversion-info {
  margin-bottom: 24px;
}

.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 14px;
}

.info-label {
  color: #666666;
  margin-right: 8px;
}

.info-value {
  font-weight: 500;
}

.conversion-table {
  width: 100%;
}

.table-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  padding-bottom: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #666666;
}

.table-body {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.table-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  padding: 8px 0;
  font-size: 14px;
  border-bottom: 1px solid #eee;
}

.table-row:last-child {
  border-bottom: none;
  font-weight: 500;
}

.text-right {
  text-align: right;
}

@media (max-width: 768px) {
  .table-header,
  .table-row {
    gap: 8px;
  }

  .info-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .info-value {
    margin-top: 4px;
  }
}
