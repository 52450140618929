/* Base Container */
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 16px;
  box-sizing: border-box;
}

/* ====== Header Section ====== */
.header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
  padding: 0 8px;
}

.header-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-title {
  font-size: clamp(1.5rem, 4vw, 2rem);
  font-weight: bold;
  margin: 0;
  color: #2c3e50;
}

/* ====== Main Card Section ====== */
.card {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  padding: 24px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

.card-content {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}

/* ====== Left Section ====== */
.left-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 140px;
}

.logo {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.logo:hover {
  transform: scale(1.05);
}

/* ====== Right Section ====== */
.right-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Common Section Title */
.section-title {
  font-size: clamp(1.125rem, 3vw, 1.25rem);
  font-weight: 600;
  margin-bottom: 16px;
  color: #2c3e50;
}

/* ====== Earnings List ====== */
.earnings-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.earnings-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 0;
  border-bottom: 1px solid #eef2f6;
}

.earnings-item:last-child {
  border-bottom: none;
}

.earnings-label {
  color: #64748b;
  font-size: 0.95rem;
}

.earnings-value {
  font-weight: 600;
  color: #2c3e50;
  font-size: 1rem;
  margin-left: 20px;
}

/* ====== More Options Section ====== */
.more-options {
  margin-top: 20px;
  padding: 24px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.transaction-button {
  padding: 12px 24px;
  background-color: #0ea5e9;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(14, 165, 233, 0.2);
}

.transaction-button:hover {
  background-color: #0284c7;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(14, 165, 233, 0.25);
}

.transaction-button:active {
  transform: translateY(0);
}

/* ====== Loading and Error States ====== */
.loading,
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 100px);
  text-align: center;
  padding: 2rem;
  font-size: 1.1rem;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  margin: 20px 0;
}

.loading {
  color: #64748b;
  animation: pulse 1.5s infinite ease-in-out;
}

.error {
  color: #dc3545;
}

.options-buttons {
  display: flex;
  gap: 1rem; /* Space between buttons */
  justify-content: center;
}

/* ====== Animations ====== */
@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

/* ====== Media Queries ====== */
/* Tablet Styles */
@media (max-width: 768px) {
  .container {
    padding: 12px;
  }

  .header {
    padding: 0 4px;
  }

  .card {
    padding: 16px;
  }

  .card-content {
    flex-direction: column;
    gap: 24px;
  }

  .left-section {
    margin-bottom: 0;
  }

  .logo {
    width: 80px;
    height: 80px;
  }

  .earnings-list {
    gap: 12px;
  }

  .earnings-item {
    padding: 6px 0;
  }

  .earnings-label {
    font-size: 0.9rem;
  }

  .earnings-value {
    font-size: 0.95rem;
  }

  .more-options {
    padding: 16px;
  }

  .loading,
  .error {
    min-height: calc(100vh - 80px);
  }
}

/* Mobile Styles */
@media (max-width: 480px) {
  .container {
    padding: 8px;
  }

  .header {
    gap: 8px; /* Reduced from 16px */
    margin-bottom: 8px; /* Reduced from 24px */
    padding-top: 5px; /* Added to move logo up */
  }

  .header-logo {
    width: 32px;
    height: 32px;
  }

  .more-options {
    padding: 16px 12px;
  }

  .card {
    padding: 8px 6px;
    margin-top: -8px; /* Added to reduce space between header and card */
  }

  .card-content {
    gap: 8px; /* Reduced from 24px */
  }

  .section-title {
    margin-bottom: 8px;
  }

  .left-section {
    margin-bottom: 0;
  }

  .logo {
    width: 72px;
    height: 72px;
    margin-top: 0; /* Removed top margin */
  }

  .earnings-list {
    gap: 8px;
  }

  .earnings-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .earnings-value {
    margin-left: 0;
    font-size: 1.1rem;
    color: #0ea5e9;
  }

  .transaction-button {
    width: 100%;
    padding: 12px 16px;
  }

  .loading,
  .error {
    min-height: calc(100vh - 60px);
  }

  .options-buttons {
    flex-direction: column;
  }

  .earnings-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    width: 100%;
  }

  .earnings-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 4px;
    padding: 8px;
    background-color: #f8fafc;
    border-radius: 8px;
    border: none;
  }

  .earnings-label {
    color: #64748b;
    font-size: 0.85rem;
    margin-bottom: 2px;
    white-space: nowrap;
  }

  .earnings-value {
    margin-left: 0;
    font-size: 1.1rem;
    color: #0ea5e9;
    font-weight: 600;
  }
}
