/* ====== Container Styles ====== */
.transaction-container {
  padding: 15px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

/* ====== Header Styles ====== */
.transaction-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 15px;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

.header-left h1 {
  font-size: clamp(1.25rem, 4vw, 1.75rem);
  margin: 0;
  color: #2c3e50;
}

/* Back Button */
.back-button {
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 0.2s ease;
  white-space: nowrap;
  color: #2c3e50;
}

.back-button:hover {
  background-color: #e0e0e0;
  transform: translateY(-1px);
}

.back-button:active {
  transform: translateY(0);
}

/* Limit Selector */
.limit-selector {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.limit-selector select {
  padding: 8px 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
  min-width: 70px;
  cursor: pointer;
  transition: border-color 0.2s ease;
}

.limit-selector select:hover {
  border-color: #999;
}

/* ====== Table Styles ====== */
.transactions-table {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
  width: 100%;
  overflow: hidden;
}

.transaction-history-table {
  width: 100%;
  border-collapse: collapse;
}

.transaction-history-td,
.transaction-history-th {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.transaction-history-th {
  background-color: #f8f9fa;
  font-weight: 600;
  position: sticky;
  top: 0;
  z-index: 1;
  color: #2c3e50;
}

/* ====== Column Specific Styles ====== */
.transaction-serial-number {
  color: #666;
  text-align: center;
  font-weight: 500;
}

.transaction-amount {
  font-weight: 600;
}

.transaction-amount.credit {
  color: #28a745;
}

.transaction-amount.debit {
  color: #dc3545;
}

.transaction-balance {
  font-weight: 600;
  color: #2c3e50;
}

/* Date and Description column styles */
.transaction-date,
.transaction-description {
  white-space: normal;
  min-width: 120px;
  max-width: 200px;
  word-wrap: break-word;
  line-height: 1.4;
}

/* ====== Pagination Styles ====== */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
  flex-wrap: wrap;
  padding: 10px;
}

.pagination button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  background-color: #0ea5e9;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 90px;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(14, 165, 233, 0.2);
}

.pagination button:hover:not(:disabled) {
  background-color: #0284c7;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(14, 165, 233, 0.25);
}

.pagination button:active:not(:disabled) {
  transform: translateY(0);
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  box-shadow: none;
}

.pagination .page-info {
  font-weight: 500;
  text-align: center;
  min-width: 150px;
  color: #2c3e50;
}

/* ====== Loading and Error States ====== */
.loading,
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 100px);
  text-align: center;
  padding: 2rem;
  font-size: 1.1rem;
}

.error {
  color: #dc3545;
}

/* ====== Media Queries ====== */
/* Large Screens */
@media screen and (max-width: 1024px) {
  .transaction-container {
    padding: 10px;
  }

  .transaction-history-td,
  .transaction-history-th {
    padding: 10px 12px;
    font-size: 14px;
  }
}

/* Tablet */
@media screen and (max-width: 768px) {
  .transaction-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-left {
    width: 100%;
    justify-content: space-between;
  }

  .limit-selector {
    width: 100%;
    justify-content: flex-end;
  }

  /* Table adjustments */
  .transaction-history-td,
  .transaction-history-th {
    padding: 10px;
    font-size: 13px;
  }

  .loading,
  .error {
    min-height: calc(100vh - 80px);
  }

  /* Date column */
  .transaction-date {
    white-space: normal;
    min-width: 85px;
    max-width: 100px;
  }

  /* Description column */
  .transaction-description {
    white-space: normal;
    min-width: 100px;
    max-width: 150px;
  }
}

/* Mobile */
@media screen and (max-width: 480px) {
  .transaction-container {
    padding: 8px;
  }

  .header-left h1 {
    font-size: 1.25rem;
  }

  .back-button {
    padding: 6px 12px;
    font-size: 13px;
  }

  .limit-selector {
    justify-content: flex-start;
  }

  .limit-selector label {
    font-size: 13px;
  }

  /* Table styles */
  .transaction-history-table {
    font-size: 12px;
  }

  .transaction-history-td,
  .transaction-history-th {
    padding: 10px 8px;
  }

  /* Hide serial number */
  .transaction-history-th:first-child,
  .transaction-history-td:first-child {
    display: none;
  }

  /* Date column */
  .transaction-date {
    white-space: normal;
    min-width: 85px;
    max-width: 100px;
    line-height: 1.3;
  }

  /* Description column */
  .transaction-description {
    white-space: normal;
    min-width: 100px;
    max-width: 150px;
    line-height: 1.3;
  }

  /* Amount and Balance columns */
  .transaction-history-td:nth-child(4),
  .transaction-history-td:nth-child(5) {
    white-space: nowrap;
    text-align: right;
    padding-left: 5px;
    padding-right: 5px;
  }

  /* Pagination adjustments */
  .pagination {
    gap: 10px;
  }

  .pagination button {
    padding: 6px 12px;
    min-width: 80px;
    font-size: 13px;
  }

  .pagination .page-info {
    min-width: 120px;
    font-size: 13px;
  }

  .loading,
  .error {
    min-height: calc(100vh - 60px);
  }
}
