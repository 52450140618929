/* PaymentForm.css */
/* PaymentForm.css - Part 1: Base Styles to Form Inputs */

/* Base Layout */
.payment-form-wrapper {
  min-height: 100vh;
  background-color: #f5f7fa;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-form-container {
  width: 100%;
  max-width: 600px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 24px;
}

.payment-form-container h1 {
  color: #2c3e50;
  text-align: center;
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 600;
}

/* Form Sections */
.form-section {
  margin-bottom: 24px;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
}

.form-input.lowercase {
  text-transform: lowercase;
}

.form-section h2 {
  color: #34495e;
  font-size: 18px;
  margin-bottom: 16px;
  font-weight: 500;
}

/* Form Layout */
.form-row {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-group {
  margin-bottom: 16px;
  position: relative;
}

/* Labels and Required Indicator */
.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 14px;
}

.required {
  color: #e74c3c;
  margin-left: 4px;
}

.form-input.uppercase {
  text-transform: uppercase;
}

/* Input Styles and States */
.form-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #dce0e3;
  border-radius: 6px;
  font-size: 14px;
  transition: all 0.2s ease;

  text-transform: inherit;
}

.form-input:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.1);
}

.form-input.readonly {
  background-color: #f5f7fa;
  cursor: not-allowed;
}

.form-input:disabled {
  background-color: #f5f7fa;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Error and Success States */
.form-input.error {
  border-color: #dc3545;
  background-color: #fff5f5;
}

.form-input.error:focus {
  box-shadow: 0 0 0 2px rgba(220, 53, 69, 0.1);
}

.form-group.has-error label {
  color: #dc3545;
}

.form-group.has-success .form-input {
  border-color: #28a745;
}

/* Error and Hint Messages */
.field-error,
.file-error {
  color: #dc3545;
  font-size: 13px;
  margin-top: 4px;
  display: block;
  animation: fadeIn 0.2s ease-in-out;
}

.field-hint {
  color: #6c757d;
  font-size: 13px;
  margin-top: 4px;
  display: block;
}

/* Status Messages */
.error-message {
  background-color: #fee2e2;
  color: #dc2626;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 16px;
  font-size: 14px;
  animation: slideIn 0.3s ease-out;
}

.success-message {
  background-color: #dcfce7;
  color: #16a34a;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 16px;
  font-size: 14px;
  animation: slideIn 0.3s ease-out;
}

/* Loading State */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: #3498db;
  font-size: 16px;
}

/* Section Dividers */
.section-divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 24px 0;
}

.section-divider::before,
.section-divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #dce0e3;
}

.divider-text {
  padding: 0 16px;
  color: #95a5a6;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

/* Basic Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Document Upload Section */
.documents-grid {
  display: grid;
  gap: 16px;
  margin-top: 16px;
}

/* Submit Button */
.submit-section {
  margin-top: 24px;
}

.submit-button {
  width: 100%;
  padding: 14px;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button:hover:not(:disabled) {
  background: #2980b9;
}

.submit-button:disabled {
  background: #95a5a6;
  cursor: not-allowed;
}

/* Status Messages */
.error-message {
  background-color: #fee2e2;
  color: #dc2626;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 16px;
  font-size: 14px;
}

.success-message {
  background-color: #dcfce7;
  color: #16a34a;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 16px;
  font-size: 14px;
}

/* Loading State */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: #3498db;
  font-size: 16px;
}

.aadhar-section {
  margin-bottom: 20px;
}

.aadhar-sample {
  width: 100%;

  height: auto;
  margin: 16px 0;
  border: 1px solid #dce0e3;
  border-radius: 6px;
}

/* Update the documents-grid styles */
.documents-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Make it two columns */
  gap: 16px;
  margin-top: 16px;
}

.otp-section {
  margin-top: 20px;
  padding: 16px;
  background: #f8fafc;
  border-radius: 8px;
}

.otp-section .form-group {
  max-width: 100%; /* Changed from fixed width */
  margin: 0 auto;
  text-align: center;
}

.otp-section .form-input {
  text-align: center;
  letter-spacing: 4px;
  font-size: 16px;
  padding: 12px;
  height: 48px;
  width: 100%;
  border: 1px solid #dce0e3;
  border-radius: 6px;
  margin-bottom: 16px; /* Added gap between input and button */
}

.otp-actions {
  display: flex;
  flex-direction: column;
  gap: 12px; /* Space between buttons/elements */
  align-items: center;
}

.otp-section .submit-button {
  width: 100%; /* Match parent width */
}

.otp-error-message {
  width: 100%;
  margin: 8px auto 16px; /* Added bottom margin */
  background-color: #fff5f5;
  border: 1px solid #fee2e2;
  border-radius: 6px;
  padding: 10px;
  text-align: center;
}

.otp-section .form-input.error {
  border-color: #e74c3c;
  background-color: #fff5f5;
}

.otp-error-message {
  width: 100%;
  max-width: 250px;
  margin: 8px auto 0;
  background-color: #fff5f5;
  border: 1px solid #fee2e2;
  border-radius: 6px;
  padding: 10px;
  text-align: center;
}

.otp-error-message p {
  color: #e74c3c;
  font-size: 13px;
  margin: 0;
}

.otp-error-message .retry-message {
  color: #64748b;
  font-size: 12px;
  margin-top: 4px;
}

/* Updated verification status styles */
.verification-status {
  text-align: center;
  padding: 16px;
  margin-top: 16px;
  border-radius: 6px;
  animation: fadeIn 0.3s ease-in-out;
}

.verification-status.success {
  background-color: #dcfce7;
  border: 1px solid #86efac;
}

.verification-status h3 {
  color: #16a34a;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.verification-status p {
  color: #166534;
  font-size: 14px;
  line-height: 1.5;
}

/* Redirect message styles */
.redirect-message {
  margin-top: 12px;
  padding: 8px;
  background-color: #f8fafc;
  border-radius: 4px;
  font-size: 13px;
  color: #64748b;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.redirect-message .spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #e2e8f0;
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Restyled resend timer and button */
.resend-timer {
  color: #64748b;
  font-size: 14px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 100%;
}

.resend-button {
  background: none;
  border: none;
  color: #3498db;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  padding: 4px 8px;
  transition: all 0.2s ease;
}

.resend-button:hover:not(:disabled) {
  color: #2980b9;
  text-decoration: none;
}

.resend-button:disabled {
  color: #94a3b8;
  cursor: not-allowed;
  text-decoration: none;
}

.field-error {
  color: #dc3545;
  font-size: 0.8rem;
  margin-top: 0.25rem;
  display: block;
}

.field-hint {
  color: #6c757d;
  font-size: 0.8rem;
  margin-top: 0.25rem;
  display: block;
}

.form-input.error {
  border-color: #dc3545;
}

.otp-section .form-input:disabled {
  background-color: #f5f7fa;
  cursor: not-allowed;
  opacity: 0.7;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Mobile Responsive Styles */
@media (max-width: 480px) {
  .payment-form-wrapper {
    padding: 12px;
  }

  .payment-form-container {
    padding: 16px;
  }

  .payment-form-container h1 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .form-section {
    padding: 16px;
    margin-bottom: 16px;
  }

  .submit-button {
    padding: 12px;
    font-size: 14px;
  }

  .documents-grid {
    grid-template-columns: 1fr; /* Stack on mobile */
  }

  .otp-section {
    padding: 16px;
  }

  .verification-status {
    padding: 12px;
  }

  .verification-status h3 {
    font-size: 16px;
  }

  .verification-status p {
    font-size: 13px;
  }

  .otp-section .form-group {
    max-width: 180px;
  }

  .otp-section .form-input {
    font-size: 15px;
    padding: 10px;
    height: 45px;
  }

  .otp-error-message {
    max-width: 250px;
    padding: 10px;
  }
}
