/* Base container styles */
.file-upload {
  margin-bottom: 12px;
  width: 100%;
}

.file-upload.disabled {
  opacity: 0.7;
  cursor: not-allowed;
  pointer-events: none;
}

/* Label styles */
.file-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #2c3e50;
}

.required {
  color: #e74c3c;
  margin-left: 2px;
}

/* Upload area styles */
.upload-area {
  position: relative;
  width: 100%;
  height: 80px;
  border: 1px dashed #dce0e3;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.2s ease;
  background: #f8f9fa;
}

.upload-area:hover:not(.disabled) {
  border-color: #3498db;
  background: #f0f7ff;
}

.upload-area:focus:not(.disabled) {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.upload-area.disabled {
  background-color: #f5f7fa;
  border-color: #e2e8f0;
  cursor: not-allowed;
  pointer-events: none;
}

/* File input styles */
.file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.file-input:disabled {
  cursor: not-allowed;
}

/* Upload placeholder styles */
.upload-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  pointer-events: none;
  user-select: none;
  width: 100%;
  padding: 0 10px;
}

.upload-placeholder span {
  display: block;
  color: #2c3e50;
  font-size: 14px;
  margin-bottom: 4px;
}

.upload-placeholder.disabled span {
  color: #94a3b8;
}

.upload-placeholder small {
  display: block;
  color: #95a5a6;
  font-size: 12px;
}

/* Preview container styles */
.preview-container {
  position: relative;
  width: 100%;
  height: 120px;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 4px;
  background: #f8f9fa;
  border: 1px solid #dce0e3;
}

.preview-container.disabled {
  opacity: 0.7;
  pointer-events: none;
}

.image-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

/* Remove button styles */
.remove-button {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #e74c3c;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 16px;
  line-height: 1;
  transition: background-color 0.2s ease;
}

.remove-button:hover:not(:disabled) {
  background: #c0392b;
}

.remove-button:focus:not(:disabled) {
  outline: none;
  box-shadow: 0 0 0 2px rgba(231, 76, 60, 0.3);
}

.remove-button:disabled {
  background: #95a5a6;
  cursor: not-allowed;
}

/* Error text styles */
.error-text {
  color: #e74c3c;
  font-size: 12px;
  margin-top: 4px;
}

/* Loading state styles */
.upload-area.is-loading {
  border-color: #3498db;
  background: #f0f7ff;
}

/* Additional interactive states */
.upload-area:active:not(.disabled) {
  border-color: #2980b9;
  background: #e6f3ff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .preview-container {
    height: 100px;
  }

  .upload-placeholder span {
    font-size: 13px;
  }

  .upload-placeholder small {
    font-size: 11px;
  }
}

/* Accessibility improvements */
.upload-area:focus-visible:not(.disabled) {
  outline: 2px solid #3498db;
  outline-offset: 2px;
}
