/* UserRatingsDashboardStyles.css */
.ratings-dashboard {
  --black: #000000;
  --white: #ffffff;
  --gray-950: #0a0a0a;
  --gray-900: #171717;
  --gray-800: #262626;
  --gray-700: #404040;
  --gray-600: #525252;
  --gray-500: #737373;
  --gray-400: #a3a3a3;
  --gray-300: #d4d4d4;
  --blue-500: #3b82f6;
  --green-900: #064e3b;
  --red-900: #7f1d1d;
  --yellow-900: #713f12;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;

  background-color: var(--black);
  min-height: 100vh;
  width: 100%;
  color: var(--white);
  padding: 2rem;
  margin: 0 auto;
  box-sizing: border-box;
}

.ratings-dashboard .dashboard-content {
  max-width: 48rem;
  margin: 0 auto;
}

.ratings-dashboard .dashboard-title {
  text-align: center;
  font-size: 1.5rem;
  color: #077cf0;
}

/* View Selector Dropdown */
.view-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.view-selector-wrapper {
  position: relative;
  display: inline-block;
}

.view-select {
  appearance: none;
  background-color: var(--gray-900);
  color: var(--white);
  border-radius: 9999px;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  font-size: 0.875rem;
  border: 1px solid var(--gray-700);
  cursor: pointer;
  outline: none;
  width: 250px;
  /* Changed from width: 100% to fixed width */
}

.view-selector-wrapper::after {
  content: "";
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid var(--white);
  pointer-events: none;
}

/* Time Range Selector */
.time-range {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.select-wrapper {
  position: relative;
  display: inline-block;
}

.select-wrapper select {
  appearance: none;
  background-color: var(--gray-900);
  color: var(--white);
  border-radius: 9999px;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  font-size: 0.875rem;
  border: 1px solid var(--gray-700);
  cursor: pointer;
  outline: none;
  min-width: 150px;
}

.select-wrapper::after {
  content: "";
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid var(--white);
  pointer-events: none;
}

/* Stats Bar */
.stats-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--gray-900);
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  margin-bottom: 2rem;
  flex-wrap: nowrap;
  gap: 1.5rem;
  min-height: 3rem;
}

.stats-bar .stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  min-width: 0;
  overflow: hidden;
}

.stats-bar .stat-value {
  font-size: 1.5rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.stats-bar .stat-label {
  font-size: 1rem;
  color: var(--gray-400);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.stats-bar .divider {
  width: 1px;
  height: 2rem;
  background-color: var(--gray-700);
  flex-shrink: 0;
}

/* Task Cards */
.task-card {
  background-color: var(--gray-800);
  border-radius: 8px;
  padding: 1.25rem;
  border: 1px solid var(--gray-700);
  transition: background-color 0.2s ease;
}

.task-card:hover {
  background-color: var(--gray-700);
}

.task-meta {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex-wrap: wrap;
  width: 100%;
}

.task-id {
  font-size: 1rem;
  color: var(--white);
  flex-shrink: 0;
  font-weight: 500;
}

.status-badge {
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 1rem;
  text-transform: lowercase;
  flex-shrink: 0;
  color: var(--white);
  font-weight: 500;
}

.status-badge.accepted {
  background-color: var(--green-900);
}

.status-badge.rejected {
  background-color: var(--red-900);
}

.status-badge.pending {
  background-color: var(--yellow-900);
}

.ratings-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
  margin-top: 0.5rem;
}

.rating-badge {
  padding: 0.25rem 0.75rem;
  background-color: var(--gray-900);
  border: 1px solid var(--gray-700);
  border-radius: 9999px;
  font-size: 0.875rem;
  white-space: nowrap;
  color: var(--white);
}

/* Comments Section */
.task-comments {
  color: var(--white);
  margin-top: 1rem;
}

.comment-text {
  margin: 0;
  line-height: 1.6;
  font-size: 1rem !important;
  word-break: break-word;
  color: var(--white);
}

.comment-label {
  color: var(--white);
  font-weight: 600;
  margin-right: 0.25rem;
  font-size: 1rem;
}

/* Section Headers */
.user-ratings-dashboard-section-title {
  font-size: 1.125rem;
  margin-bottom: 1rem;
  color: #077cf0;
  text-align: center;
}

.tasks-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 40px;
}

/* Pagination */
.user-ratings-pagination-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--black);
  /* padding: 0.5rem 0; */
  /* border-top: 1px solid var(--gray-800); */
  z-index: 100;
  width: 100%;
}

.user-ratings-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}

.user-ratings-pagination-btn {
  background-color: transparent;
  color: #0f55ce;
  font-weight: 600;
  padding: 10px 8px;
  border: none;
  cursor: pointer;
  transition: opacity 0.2s ease;
  font-size: 14px;
}

.user-ratings-pagination-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  color: #0f55ce;
}

.user-ratings-pagination-text {
  margin: 0;
  font-size: 14px;
  color: #fa56ff;
}

.page-info {
  color: var(--white) !important;
  min-width: 3rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  flex-shrink: 0;
  letter-spacing: 0.05em;
}

/* Notifications */
.notification-card {
  transition: background-color 0.3s ease;
}

.notification-card.unread {
  background-color: var(--gray-800);
  border: 1px solid var(--gray-700);
}

.notification-card.read {
  background-color: var(--gray-900);
  border: 1px solid var(--gray-800);
  opacity: 0.7;
}

.notification-message,
.notification-additional {
  margin: 0.5rem 0;
  line-height: 1.6;
  font-size: 1rem !important;
  word-break: break-word;
  color: var(--white);
}

.notification-date {
  color: var(--gray-300);
  font-size: 0.875rem !important;
  margin-top: 0.75rem;
}

.notifications-section {
  margin-bottom: 2rem;
}

.notifications-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Loading and Error States */
.loading,
.no-tasks,
.error-message {
  text-align: center;
  padding: 2rem;
  color: var(--gray-400);
  background-color: var(--gray-900);
  border-radius: 0.75rem;
  margin-top: 1rem;
}

.error-message {
  color: var(--red-900);
}

.status-badge.outright-rejected {
  background-color: #ff0033; /* Brighter red */
  font-weight: 700; /* Bolder than regular badges */
  color: white;
  box-shadow: 0 0 8px rgba(255, 0, 0, 0.6); /* Subtle glow effect */
  text-transform: uppercase; /* Make it stand out more */
  letter-spacing: 0.5px;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .ratings-dashboard {
    padding: 1rem;
  }

  .view-select {
    max-width: 100%;
  }

  .view-selector-wrapper {
    max-width: 100%;
  }

  .pagination {
    padding: 0.5rem;
    gap: 0.75rem;
    min-width: 140px;
  }

  .page-button {
    width: 2rem;
    height: 2rem;
  }

  .stats-bar {
    padding: 0.5rem 1rem;
    gap: 0.75rem;
  }

  .stats-bar .stat-value {
    font-size: 1.1rem;
  }

  .stats-bar .stat-label {
    font-size: 0.75rem;
  }

  .task-meta {
    gap: 0.5rem;
  }

  .ratings-list {
    gap: 0.25rem;
  }

  .rating-badge {
    padding: 0.2rem 0.4rem;
    font-size: 0.7rem;
  }

  .select-wrapper select {
    width: 100%;
    min-width: 200px;
  }

  .comment-text {
    font-size: 0.8rem !important;
  }

  .notification-message,
  .notification-additional {
    font-size: 0.8rem !important;
  }

  .notification-date {
    font-size: 0.7rem !important;
  }
}

@media (max-width: 480px) {
  .ratings-dashboard {
    padding: 0.75rem;
  }

  .view-select {
    max-width: 100%;
  }

  .stats-bar {
    padding: 0.5rem 0.75rem;
    gap: 0.5rem;
  }

  .stats-bar .stat-value {
    font-size: 1rem;
  }

  .stats-bar .stat-label {
    font-size: 0.7rem;
  }

  .task-meta {
    gap: 0.4rem;
  }

  .notifications-section {
    margin-bottom: 1.5rem;
  }

  .notification-card {
    padding: 1rem;
  }
}
