/* Update Language UI */
.update-language-container {
    padding: 16px;
    background: #e2effd;
    height: 100vh;
  }
  
  .language-update-fieldset {
    /* border: 2px solid #ddd;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px; */
    margin-bottom: 20px;
    border: 1px solid #5e96f7;
    background-color: rgb(194 215 251);
    padding: 10px 16px;
    box-shadow: 0 0px 2px rgb(107 107 107);
    border-radius: 4px;
    overflow: visible;
  }
  
  .language-update-fieldset legend {
    border: 1px solid #5d96ff;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    background-color: #c2d7fe;
    width: auto;
    margin: 0px;
    display: inline-block;
    text-align: center;
    color: #0b2658;
  }
  
  .language-list {
    list-style: none;
    padding: 0;
  }
  
  .language-item {
    background-color: #f9f9f9;
    padding: 15px;
    margin: 10px 0;
    border-radius: 8px;
    border: 1px solid #ccc;
  }
  
  .language-info {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
  
  .language-name {
    font-weight: bold;
    color: #333;
  }
  
  .checkbox-group {
    display: flex;
    flex-wrap: wrap;
  }
  
  .checkbox-label {
    margin-right: 20px;
    font-size: 1rem;
  }
  
  .checkbox-label input {
    margin-left: 8px;
  }
  
  h1 {
    font-size: 18px;
    color: #053b75;
    text-align: center;
    margin-top: 24px;
  }

/* Add styles for error message and submit button */
.error-message {
    color: red;
    font-weight: bold;
    margin-bottom: 10px;
  }


  
  