/* src/index.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* // new filter */

.cs-video-filters {
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  transition: all 0.3s linear;
  border: 1px solid #ccc;
  padding: 4px 10px;
  border-radius: 5px;
  margin: 6px 0 12px 0;
}

.cs-video-filters-control {
  height: auto !important;
}

.cs-video-filters-container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  column-gap: 8px;
  align-items: center;
  font-size: 14px;
}

.form-group {
  margin: 3px 0;

  label {
    font-size: 12px;
    margin-bottom: 5px !important;
    color: #666;
  }

}
/* Styles for the install popup in a single line */
.install-popup {
  width: 70vw;
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  color: #333;
  font-size: 14px;
  display: flex;            /* Use flexbox to arrange elements in a row */
  align-items: center;      /* Vertically align the items */
  justify-content: center;  /* Optionally center the content horizontally */
  gap: 10px;                /* Add space between the text and the button */
}

.install-popup p {
  margin: 0;                /* Remove default margin around the text */
  font-size: 14px;          /* Optional: Adjust text size */
}

.install-popup button {
  padding: 8px 12px;        /* Adjust button size */
  background-color: #202ea1;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;          /* Optional: Adjust button text size */
}

.install-popup button:hover {
  background-color: #005a9e;
}
