.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65); /* Slightly reduced opacity */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  backdrop-filter: blur(8px); /* Add blur to the overlay */
  -webkit-backdrop-filter: blur(8px); /* For Safari support */
}

.modal-content {
  background: #111111;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 560px;
  margin-top: 0;
  outline: none;
  color: white;
  border: 1px solid #2e2e2e;
  animation: modalFadeIn 0.3s ease-out;
}

.modal-container {
  position: relative;
}

.modal-header {
  padding: 16px 24px;
  border-bottom: 1px solid #2e2e2e; /* Changed border color to match theme */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: white; /* Added white text color */
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: white; /* Updated color to match theme */
  padding: 4px 8px;
  line-height: 1;
}

.close-button:hover {
  color: white; /* Changed hover color to white */
}

.modal-body {
  padding: 24px;
}

.details-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.detail-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.detail-item label {
  font-size: 14px;
  color: #6b6b6b; /* Updated color to match theme */
  font-weight: 500;
}

.detail-item p {
  margin: 0;
  color: white; /* Changed text color to white */
}

.modal-loading {
  text-align: center;
  padding: 32px 0;
  color: #6b6b6b; /* Updated color to match theme */
}

.modal-error {
  text-align: center;
  padding: 32px 0;
  color: #6b6b6b; /* Updated color to match theme */
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
